'use strict';

import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import axios from 'axios';

/**
 * STORE MODULES
 */
import account from './modules/account';
import auth from './modules/auth';
import app from './modules/app';
import config from './modules/config';
import staticdata from './modules/staticdata';
import search from './modules/search';
import { event } from 'vue-analytics';

Vue.use(Vuex);

const vuexPersist = new VuexPersistence({
  key: 'campusMatchConfiguration',
  storage: window.localStorage,
  modules: ['auth', 'config', 'account', 'staticdata', 'search'],
});

const strict = process.env.NODE_ENV === 'development';

export const Store = new Vuex.Store({
  plugins: [vuexPersist.plugin],
  modules: {
    account,
    auth,
    app,
    config,
    staticdata,
    search,
  },
  strict,
  // https://github.com/MatteoGabriele/vue-analytics/blob/master/docs/vuex.md
  state: {
    counter: 0,
  },
  actions: {
    increase ({ commit, state }) {
      commit('increase', state.counter + 1);
    },
  },
  mutations: {
    increase (state, payload) {
      state.counter = payload;
      event('user-click', 'increase', 'counter', state.counter);
    },
  },
});

axios.interceptors.response.use(undefined, function (error) {
  if (error.response && error.response.status) {
    // if HTTP Status Code is '401 UNAUTHORIZED', force logout
    if (error.response.status === 401) {
      Store.dispatch('logoutIfAuthenticated');
    } else {
      return error;
    }
  }
});

export default Store;
