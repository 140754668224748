import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue';
import VueMeta from 'vue-meta';

import '@/api/mock';

import i18n from '@/config/translations';
import '@/config/bootstrap';
import '@/config/font-awesome';
import '@/config/vue-head';
// import '@/config/vuetify';
import '@/style/main.scss';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/config/matomo';
import '@/config/analytics';

Vue.config.productionTip = false;

Vue.use(VueMeta);

// fetch profile data in init, 'cause only token is persistent
store.dispatch('updateProfile');

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  mounted () {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app');

if (process.env.NODE_ENV === 'development' && process.env.API_XDEBUG) {
  const devNode = document.createElement('div');
  devNode.setAttribute('id', 'dev-app');
  document.body.appendChild(devNode);

  new Vue({
    render: (h) => h(() => import('@/components/BaSymfonyDebugToolbar'/* webpackChunkName: 'component~BaSymfonyDebugToolbar' */)),
  }).$mount('#dev-app');
}
