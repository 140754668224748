import permissions from '@/services/permissions';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'/* webpackChunkName: 'page~Home' */),
    meta: {
      public: true,
    },
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import('@/views/Onboarding.vue'/* webpackChunkName: 'page~Onboarding' */),
    meta: {
      public: false,
      only_for: 'applicant',
    },
  },
  {
    path: '/passwort-vergessen',
    name: 'password-request',
    component: () => import('@/views/PasswordRequest.vue'/* webpackChunkName: 'page~PasswordRequest' */),
    meta: {
      public: true,
    },
  },
  {
    path: '/passwort-vergessen/email',
    name: 'password-recovery-email',
    component: () => import('@/views/PasswordRequestEmail.vue'/* webpackChunkName: 'page~PasswordRequestEmail' */),
    meta: {
      public: true,
    },
  },
  {
    path: '/passwort-vergessen/erneuern/:token?',
    name: 'password-recovery',
    component: () => import('@/views/PasswordRecovery.vue'/* webpackChunkName: 'page~PasswordRecovery' */),
    meta: {
      public: true,
    },
  },
  {
    path: '/passwort-vergessen/erfolgreich',
    name: 'password-recovery-success',
    component: () => import('@/views/PasswordRecoverySuccess.vue'/* webpackChunkName: 'page~PasswordRecoverySuccess' */),
    meta: {
      public: true,
    },
  },
  {
    path: '/account-geloescht/:accountType?',
    name: 'account-deleted',
    component: () => import('@/views/AccountDeleted.vue'/* webpackChunkName: 'page~AccountDeleted' */),
    meta: {
      public: true,
    },
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/Account.vue'/* webpackChunkName: 'page~Account' */),
    meta: {
      public: false,
    },
  },
  {
    path: '/bewerbungsprofil',
    name: 'profile',
    component: () => import('@/views/Profile.vue'/* webpackChunkName: 'page~Profile' */),
    meta: {
      public: false,
      only_for: 'applicant',
    },
  },
  {
    path: '/bewerber',
    name: 'applicantsearch',
    component: () => import('@/views/ApplicantSearch.vue'/* webpackChunkName: 'page~ApplicantSearch' */),
    meta: {
      public: false,
      only_for: 'company',
    },
  },
  {
    path: '/bewerber/suche/:searchId?',
    name: 'applicantsearchprofile',
    component: () => import('@/views/ApplicantSearch.vue'/* webpackChunkName: 'page~ApplicantSearch' */),
    meta: {
      public: false,
      only_for: 'company',
    },
  },
  {
    path: '/bewerber/suchauftraege',
    name: 'applicantsearchprofiles',
    component: () => import('@/views/ApplicantSearchProfiles.vue'/* webpackChunkName: 'page~ApplicantSearchProfiles' */),
    meta: {
      public: false,
      only_for: 'company',
    },
  },
  {
    path: '/bewerber/profil/:applicantId',
    name: 'applicantprofile',
    component: () => import('@/views/ApplicantProfile.vue'/* webpackChunkName: 'page~ApplicantProfile' */),
    meta: {
      public: false,
      only_for: 'company',
    },
  },
  {
    path: '/bewerber/merkliste',
    name: 'applicantbookmarks',
    component: () => import('@/views/ApplicantBookmarks.vue'/* webpackChunkName: 'page~ApplicantBookmarks' */),
    meta: {
      public: false,
      only_for: 'company',
    },
  },
  {
    path: '/unternehmenssuche',
    name: 'companysearch',
    component: () => import('@/views/CompanySearch.vue'/* webpackChunkName: 'page~CompanySearch' */),
    meta: {
      public: true,
    },
  },
  {
    path: '/unternehmensprofil',
    name: 'owncompanyprofile',
    component: () => import('@/views/Profile.vue'/* webpackChunkName: 'page~Profile' */),
    meta: {
      public: false,
      only_for: 'company',
      permission: permissions.COMPANY_EDIT_PROFILE,
    },
  },
  {
    path: '/unternehmen/suche/:searchId?',
    name: 'companysearchprofile',
    component: () => import('@/views/CompanySearch.vue'/* webpackChunkName: 'page~CompanySearch' */),
    meta: {
      public: true,
    },
  },
  // {
  //   path: '/unternehmen/suchprofile',
  //   name: 'companyprofiles',
  //   component: () => import('@/views/CompanySearchProfiles.vue'/* webpackChunkName: 'page~CompanySearchProfiles' */),
  //   meta: {
  //     public: true,
  //   },
  // },
  {
    path: '/unternehmen/profil/:companyId',
    name: 'companyprofile',
    component: () => import('@/views/CompanyProfile.vue'/* webpackChunkName: 'page~CompanyProfile' */),
    meta: {
      public: true,
    },
  },
  {
    path: '/unternehmen/merkliste',
    name: 'companybookmarks',
    component: () => import('@/views/CompanyBookmarks.vue'/* webpackChunkName: 'page~CompanyBookmarks' */),
    meta: {
      public: false,
      only_for: 'applicant',
    },
  },
  {
    path: '/nachrichten',
    name: 'messages',
    component: () => import('@/views/Messages.vue'/* webpackChunkName: 'page~Messages' */),
    meta: {
      public: false,
    },
  },
  {
    path: '/nachrichten/:threadId',
    name: 'message-open',
    component: () => import('@/views/Messages.vue'/* webpackChunkName: 'page~Messages' */),
    meta: {
      public: false,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'/* webpackChunkName: 'page~Dashboard' */),
    meta: {
      public: false,
    },
  },
  {
    path: '/registrierung/:type?',
    name: 'register',
    component: () => import('@/views/Register.vue'/* webpackChunkName: 'page~Register' */),
    meta: {
      public: true,
    },
  },
  {
    path: '/registrierung-verifikation/:token?',
    name: 'register-verification',
    component: () => import('@/views/RegisterVerification.vue'/* webpackChunkName: 'page~RegisterVerification' */),
    meta: {
      public: true,
    },
  },
  {
    path: '/signup-confirmed',
    name: 'signup-confirmed',
    component: () => import('@/views/SignupSuccessConfirmed.vue'/* webpackChunkName: 'page~SignupSuccessConfirmed' */),
    meta: {
      public: true,
    },
  },
  {
    path: '/studiengaenge',
    name: 'studyprograms',
    component: () => import('@/views/StudyPrograms.vue'/* webpackChunkName: 'page~StudyPrograms' */),
    meta: {
      public: true,
    },
  },
  {
    path: '/studienvorbereitung',
    name: 'studypreparation',
    component: () => import('@/views/StudyPreparation.vue'/* webpackChunkName: 'page~StudyPreparation' */),
    meta: {
      public: true,
    },
  },
  {
    path: '/mathx3',
    name: 'mathx',
    component: () => import('@/views/MathX.vue'/* webpackChunkName: 'page~MathX' */),
  },
  {
    path: '/kontakt',
    name: 'contact',
    component: () => import('@/views/Contact.vue'/* webpackChunkName: 'page~Contact' */),
    meta: {
      public: true,
    },
  },
  {
    path: '/impressum',
    name: 'impress',
    component: () => import('@/views/static/ImpressPage.vue'/* webpackChunkName: 'page~ImpressPage' */),
    meta: {
      public: true,
    },
  },
  {
    path: '/datenschutz',
    name: 'privacy',
    component: () => import('@/views/static/PrivacyPage.vue'/* webpackChunkName: 'page~PrivacyPage' */),
    meta: {
      public: true,
    },
  },
  {
    path: '/nutzungsbedingungen',
    name: 'agb',
    component: () => import('@/views/static/BusinessTermsAndConditionsPage.vue'/* webpackChunkName: 'page~BusinessTermsAndConditionsPage' */),
    meta: {
      public: true,
    },
  },
  {
    path: '/barrierefreiheit',
    name: 'accessibility',
    component: () => import('@/views/static/AccessibilityPage.vue'/* webpackChunkName: 'page~AccessibilityPage' */),
    meta: {
      public: true,
    },
  },
  {
    path: '*',
    name: 'notfound',
    component: () => import('@/views/NotFound.vue'/* webpackChunkName: 'page~NotFound' */),
    meta: {
      public: true,
    },
  },
];

export default routes;
