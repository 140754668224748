import Vue from 'vue';
import VueAnalytics from 'vue-analytics';

import router from '@/router';

const GoogleAnalyticsId = process.env.GOOGLE_ANALYTICS_ID;

if (window.__PRERENDER_INJECTED === undefined && GoogleAnalyticsId && window._trackingEnabled) {
  Vue.use(VueAnalytics, {
    id: GoogleAnalyticsId,
    router,
    // https://github.com/MatteoGabriele/vue-analytics/blob/master/docs/turn-off-development.md
    debug: {
      sendHitTask: process.env.NODE_ENV === 'production',
    },
  });
}
