<template>
  <b-navbar-nav class="topbar-menu-container" v-if="this.isAuthenticated">
    <b-nav-item key="home" :to="{name:'home'}">
      <span class="has-text-icon">
          <span class="icon-circle bg-info">
            <font-awesome-icon class="link-icon" icon="home"/>
          </span>
          <span class="text">{{ $t('action_home') }}</span>
      </span>
    </b-nav-item>
    <ba-navbar-menu :message-count="messageCount"/>
    <b-nav-item key="logout" @click="logout()">
      <span class="has-text-icon">
          <span class="icon-circle bg-info">
            <font-awesome-icon class="link-icon" icon="user"/>
          </span>
          <span class="text">{{ $t('action_logout') }}</span>
      </span>
    </b-nav-item>
  </b-navbar-nav>
  <b-navbar-nav class="topbar-menu-container" v-else>
    <b-nav-item key="home" :to="{name:'home'}">
      <span class="has-text-icon">
          <span class="icon-circle bg-info">
            <font-awesome-icon class="link-icon" icon="home"/>
          </span>
          <span class="text">{{ $t('action_home') }}</span>
      </span>
    </b-nav-item>
    <b-nav-dropdown right variant="text" :no-caret="true" ref="login-dropdown">
      <template v-slot:button-content>
        <span class="has-text-icon">
          <span class="icon-circle bg-info">
              <font-awesome-icon class="link-icon" icon="sign-in-alt"/>
            </span>
          <span class="text">{{ $t('action_login') }}</span>
        </span>
      </template>
      <ba-login-form id="nav-login-form-desktop" @close="closeLoginDropdown"/>
    </b-nav-dropdown>
    <b-nav-item key="register" :to="{ name: 'register' }">
      <span class="has-text-icon">
          <span class="icon-circle bg-info">
            <font-awesome-icon class="link-icon" icon="user-plus"/>
          </span>
          <span class="text">{{ $t('action_register') }}</span>
      </span>
    </b-nav-item>
  </b-navbar-nav>
</template>

<script>
  import BaLoginForm from '@/components/partials/Header/BaLoginForm';
  import BaNavbarMenu from '@/components/partials/Header/BaNavbarMenu';

  import { mapActions, mapGetters } from 'vuex';

  export default {
    components: {
      BaLoginForm,
      BaNavbarMenu,
    },
    props: {
      messageCount: {
        type: Number,
        required: false,
        default: 0,
      },
    },
    computed: {
      ...mapGetters(['isAuthenticated', 'activeAccount']),
    },
    methods: {
      ...mapActions(['logout']),
      closeLoginDropdown: function () {
        this.$refs['login-dropdown'].visible = false;
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .topbar-menu-container {
    display: none;
    max-width: 70%;

    .dropdown-menu-right {
      margin-top: 0;
      border: 1px solid $ba-text-color;
      //border-top-width: 0;
    }

    > .nav-item > .nav-link {
      font-size: $ba-nav-top-font-size;
      //letter-spacing: .9px;
      text-transform: uppercase;
      padding-bottom: 0;
      .link-icon {
        display: inline-block;
        margin-right: 10px;
      }
      .icon-circle {
        .link-icon {
          margin-right: 0;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: right;
      // compensate padding of last nav item
      margin-right: -.5rem;
      padding: 0;
      > .nav-item {
        margin-left: 21px;
        > .nav-link {
          padding-left: 9px;
          padding-right: 9px;
          padding-bottom: 9px;
          line-height: 21px;
          transition: background-color .2s linear;
          .bg-info {
            transition: background-color .2s linear;
          }
          @include hover-focus {
            background: $ba-file-link-hover-color;//$ba-light-primary;
            .bg-info {
              background: $ba-file-link-hover-color !important;//$ba-light-primary;
            }
          }
        }
        &.show {
          > .nav-link {
            background: $ba-file-link-hover-color;//$ba-light-primary;
            .bg-info {
              background: $ba-file-link-hover-color !important;//$ba-light-primary;
            }
          }
        }
      }
    }
  }
  .b-nav-dropdown {
    text-align: center;
    //min-width: 10rem;
  }
</style>

<i18n lang="yaml" locale="de">
  action_home: 'Home'
  action_logout: 'Logout'
  action_login: 'Login'
  action_register: 'Registrierung'
</i18n>
