<template>
  <div class="language-select-container">&nbsp;</div>
  <!--
  <b-navbar-nav class="language-select-container">
    <b-nav-item
      v-for="(lang, code) in $language.available"
      :key="code"
      class="language-select"
      :class="{active: activeLanguage === code }"
      @click="changeLanguage(code)">{{ lang }}</b-nav-item>
  </b-navbar-nav>
  -->
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    methods: {
      ...mapActions(['changeLanguage']),
    },
    computed: {
      ...mapGetters(['activeLanguage']),
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .language-select-container {
    position: relative;
    font-size: $ba-font-size-sm;
    letter-spacing: .9px;
    text-transform: uppercase;
    flex-direction: row;
    background-color: $ba-bg-primary;
    max-width: 100%;
    padding: 0 21px;
    // compensate padding of first nav item
    margin-left: -.5rem;

    > .nav-item {
      &:first-child {
        position: relative;
        padding-right: 7px;
        margin-right: 5px;

        &::after {
          content: "";
          display: block;
          width: 2px;
          height: 14px;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -7px;
          background: $ba-light-primary;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      padding: 0;
    }
  }
</style>
