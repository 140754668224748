import Vue from 'vue';
import VueMatomo from 'vue-matomo';

import router from '@/router';

const MatomoHost = process.env.MATOMO_HOST;
const MatomoSiteId = process.env.MATOMO_SITE_ID;

if (window.__PRERENDER_INJECTED === undefined &&
  MatomoHost !== undefined && MatomoSiteId !== undefined && MatomoHost.length > 0 && MatomoSiteId > 0) {
  Vue.use(VueMatomo, {
    // Configure your matomo server and site by providing
    host: MatomoHost,
    siteId: MatomoSiteId,

    // Changes the default .js and .php endpoint's filename
    // Default: 'matomo'
    trackerFileName: 'matomo',

    // Overrides the autogenerated tracker endpoint entirely
    // Default: undefined
    // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

    // Overrides the autogenerated tracker script path entirely
    // Default: undefined
    // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

    // Enables automatically registering pageviews on the router
    router: router,

    // Enables link tracking on regular links. Note that this won't
    // work for routing links (ie. internal Vue router links)
    // Default: true
    enableLinkTracking: false,

    // Require consent before sending tracking information to matomo
    // Default: false
    requireConsent: true,

    // Whether to track the initial page view
    // Default: true
    trackInitialView: true,

    // Run Matomo without cookies
    // Default: false
    disableCookies: false,

    // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
    // Default: false
    enableHeartBeatTimer: true,

    // Set the heartbeat timer interval
    // Default: 15
    heartBeatTimerInterval: 60,

    // Whether or not to log debug information
    // Default: false
    debug: false,

    // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
    // Default: undefined
    userId: undefined,

    // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
    // Default: undefined, example '*.example.com'
    cookieDomain: undefined,

    // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
    // Default: undefined, example: '*.example.com'
    domains: undefined,

    // A list of pre-initialization actions that run before matomo is loaded
    // Default: []
    // Example: [
    //   ['API_method_name', parameter_list],
    //   ['setCustomVariable','1','VisitorType','Member'],
    //   ['appendToTrackingUrl', 'new_visit=1'],
    //   etc.
    // ]
    preInitActions: [],
  });

  router.afterEach((to, from) => {
    if (!window._paq) {
      window._paq = [];
    }
    window._paq.push(['setDocumentTitle', to.name]);
    if (from && from.name) {
      window._paq.push(['setReferrerUrl', window.origin + from.fullPath]);
    }

    // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
    window._paq.push(['deleteCustomVariables', 'page']);
    window._paq.push(['setGenerationTimeMs', 0]);

    // make Matomo aware of newly added content
    var content = document.getElementById('app');
    window._paq.push(['MediaAnalytics::scanForMedia', content]);
    window._paq.push(['FormAnalytics::scanForForms', content]);
    window._paq.push(['trackContentImpressionsWithinNode', content]);
    window._paq.push(['enableLinkTracking']);
  });
}
