'use strict';

import CustomConfig from '@/config.json';

const DefaultConfig = {
  projectName: 'campusMatch',
  projectLogo: '/logo_weincampus.svg',
  customer: {
    name: 'Weincampus Neustadt',
    email: 'weincampus@hwg-lu.de',
    phone: '+49 (0) 6321/671-509',
    logo: '/logo_weincampus.svg',
    logoFooter: '/logo_weincampus.svg',
    website: 'https://www.weincampus-neustadt.de/',
    address: {
      street: 'Breitenweg 71',
      zipcode: '67435',
      town: 'Neustadt an der Weinstraße',
    },
  },
  logoLink: 'https://www.weincampus-neustadt.de/',
  // Beware: object sub.properties must be set in config.json with all properties!
  socialLinks: {
    facebook: 'https://www.facebook.com/WeincampusNeustadt',
    // xing: 'https://www.xing.com/pages/weincampusneustadt',
    youtube: 'https://www.youtube.com/channel/UCQ70qeXNonfYDp7DPjv5aKg',
    instagram: 'https://www.instagram.com/weincampus',
  },
  staticPages: {
    imprint: true,
    privacy: true,
    terms: true,
    accessibility: false,
    contact: true,
  },
  applicant: {
    enableMatches: false,
  },
  company: {
    // toggle visibility for company study offers; either 'all' or 'authenticated'
    studyOffersVisibility: 'all',
    map: {
      enable: false,
      fitBounds: true,
      zoom: 10,
    },
    profileRedirectToUrlIfNotAuthenticated: null,
  },
  contactMap: {
    zoom: 16,
    // https://www.geoplaner.de/
    center: {
      latitude: 49.36969,
      longitude: 8.17925,
    },
    marker: {
      latitude: 49.369900,
      longitude: 8.186510,
    },
  },
};
const Config = Object.assign({}, DefaultConfig, CustomConfig);
// Merge customer and company properties, so we don't have to override the whole configuration for these sections
if (CustomConfig.company) {
  Config.customer = Object.assign({}, DefaultConfig.customer, CustomConfig.customer);
}
if (CustomConfig.company) {
  Config.company = Object.assign({}, DefaultConfig.company, CustomConfig.company);
}

export default Config;
