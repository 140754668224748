import { render, staticRenderFns } from "./BaHeader.vue?vue&type=template&id=7129d9dc&"
import script from "./BaHeader.vue?vue&type=script&lang=js&"
export * from "./BaHeader.vue?vue&type=script&lang=js&"
import style0 from "./BaHeader.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./BaHeader.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml&locale=de"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/gerthammes/dev/campus-match/weincampus-campus-match-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7129d9dc')) {
      api.createRecord('7129d9dc', component.options)
    } else {
      api.reload('7129d9dc', component.options)
    }
    module.hot.accept("./BaHeader.vue?vue&type=template&id=7129d9dc&", function () {
      api.rerender('7129d9dc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BaHeader.vue"
export default component.exports