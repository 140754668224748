var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "navbar-top navbar-fixed",
      class: {
        fixed: _vm.isFixed,
        "navbar-collapse-show": _vm.mainMenuVisible,
        "navbar-user-logged-in": _vm.isAuthenticated
      },
      attrs: { toggleable: "lg", id: "navbar-top" }
    },
    [
      _c(
        "div",
        { staticClass: "navbar-container-lg" },
        [
          _vm.logoUrl
            ? _c(
                "b-navbar-brand",
                [
                  _c(
                    "b-link",
                    { attrs: { href: _vm.logoUrl } },
                    [
                      _c("b-img", {
                        staticClass: "brand-logo",
                        attrs: { src: _vm.brandLogo }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "brand-text" }, [
                        _vm._v("Weincampus Neustadt")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "b-navbar-brand",
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "home" } } },
                    [
                      _c("b-img", {
                        staticClass: "brand-logo",
                        attrs: { src: _vm.brandLogo }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "brand-text" }, [
                        _vm._v("Weincampus Neustadt")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
          _vm._v(" "),
          _c(
            "router-link",
            { staticClass: "nav-home", attrs: { to: { name: "home" } } },
            [
              _c("font-awesome-icon", {
                staticClass: "nav-icon",
                attrs: { size: "2x", icon: "home" }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "sr-only" }, [
                _vm._v(_vm._s(_vm.$t("action_logout")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm.isAuthenticated
            ? _c("div", { staticClass: "nav-user" }, [
                _c(
                  "span",
                  {
                    attrs: {
                      title:
                        _vm.activeAccount.firstName +
                        " " +
                        _vm.activeAccount.lastName
                    },
                    on: { click: _vm.toggleNavbarForceOpen }
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "nav-icon",
                      attrs: { size: "2x", icon: "user" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    on: {
                      click: function($event) {
                        return _vm.logout()
                      }
                    }
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "nav-icon",
                      attrs: { size: "2x", icon: "sign-out-alt" }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v(_vm._s(_vm.$t("action_logout")))
                    ])
                  ],
                  1
                )
              ])
            : _c(
                "b-navbar-toggle",
                {
                  ref: "toggle-user",
                  staticClass: "nav-user",
                  attrs: { target: "nav-collapse-user" }
                },
                [
                  _c(
                    "span",
                    [
                      _c("font-awesome-icon", {
                        staticClass: "nav-icon",
                        attrs: { size: "2x", icon: "user" }
                      })
                    ],
                    1
                  )
                ]
              ),
          _vm._v(" "),
          _c(
            "b-navbar-toggle",
            { ref: "toggle-main", attrs: { target: "nav-collapse-main" } },
            [
              _c("span", { staticClass: "icon-bar top-bar" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-bar middle-bar" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-bar bottom-bar" })
            ]
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            {
              attrs: { id: "nav-collapse-main", "is-nav": "" },
              model: {
                value: _vm.mainMenuVisible,
                callback: function($$v) {
                  _vm.mainMenuVisible = $$v
                },
                expression: "mainMenuVisible"
              }
            },
            [
              _vm.isAuthenticated
                ? _c("ba-mobile-navbar-menu", {
                    attrs: { "message-count": _vm.messageCount }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container nav-container" },
                [_c("ba-navigation")],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "navbar-top-bar" }, [
                _c(
                  "div",
                  { staticClass: "navbar-container-lg" },
                  [
                    _c("ba-language-select"),
                    _vm._v(" "),
                    _c("ba-topbar-menu", {
                      attrs: { "message-count": _vm.messageCount }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isAuthenticated
            ? _c(
                "b-collapse",
                {
                  attrs: { id: "nav-collapse-user", "is-nav": "" },
                  model: {
                    value: _vm.userMenuVisible,
                    callback: function($$v) {
                      _vm.userMenuVisible = $$v
                    },
                    expression: "userMenuVisible"
                  }
                },
                [
                  _c("ba-login-form", {
                    staticClass: "d-lg-none",
                    attrs: { context: "mobile", id: "nav-login-form-mobile" },
                    on: { close: _vm.toggleNavbarForceClose }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }