<template>
  <!-- Right aligned nav items -->
  <b-navbar-nav class="main-nav">
    <!--
    <b-nav-item :to="{name: 'studyprograms'}">
      <font-awesome-icon icon="question"/>
      <span class="text">
      {{ $t('Studiengänge') }}</span>
    </b-nav-item>
    -->
    <b-nav-item :to="{name: 'companysearch'}">
      <font-awesome-icon icon="building"/>
      <span class="text">
      {{ $t('components.partials.header.ba_navigation.companysearch') }}</span>
    </b-nav-item>
    <b-nav-item class="individual-width" :to="{name: 'register'}" v-if="!isAuthenticated">
      <font-awesome-icon icon="user"/>
      <span class="text">
      {{ $t('components.partials.header.ba_navigation.register') }}</span>
    </b-nav-item>
    <b-nav-item class="individual-width" :to="{name: 'profile'}" v-else-if="isApplicantAccount">
      <font-awesome-icon icon="user"/>
      <span class="text">
      {{ $t('components.partials.header.ba_navigation.profile') }}</span>
    </b-nav-item>
    <b-nav-item class="individual-width" :to="{name: 'applicantsearch'}" v-else-if="isCompanyAccount">
      <font-awesome-icon icon="user"/>
      <span class="text">
      {{ $t('components.partials.header.ba_navigation.applicantsearch') }}</span>
    </b-nav-item>
    <b-nav-item href="https://www.weincampus-neustadt.de/weincampus/stellenmarkt" target="_blank" class="external">
      <font-awesome-icon icon="book"/>
      <span class="text">
      {{ $t('components.partials.header.ba_navigation.jobmarket') }}</span>
    </b-nav-item>
    <b-nav-item href="https://www.weincampus-neustadt.de/kontakt" target="_blank" class="external">
      <font-awesome-icon icon="link"/>
      <span class="text">
      Kontakt</span>
    </b-nav-item>
    <!--
    <b-nav-item :to="{name: 'studypreparation'}">
      <font-awesome-icon icon="book"/>
      <span class="text">
      {{ $t('components.partials.header.ba_navigation.studypreparation') }}</span>
    </b-nav-item>
    -->
    <!--
    <b-nav-item :href="'https://www.mathx3.de'">
      <font-awesome-icon icon="calculator"/>
      <span class="text">
      {{ $t('components.partials.header.ba_navigation.mathx3') }}</span>
    </b-nav-item>
    -->
  </b-navbar-nav>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters(['isAuthenticated', 'isApplicantAccount', 'isCompanyAccount']),
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .main-nav {
    position: relative;
    z-index: 11;
    //padding-top: 18px;
    padding-bottom: 18px;
    .nav-item {
      width: 100%;
      position: relative;
      font-size: 16px;
      font-weight: $ba-font-weight-medium;
      line-height: normal;
      letter-spacing: 1.41px;
      text-transform: uppercase;
      .nav-link {
        color: $ba-navbar-link-color;
        display: block;
        padding: 18px 0 18px 0;
        transition: color .2s linear;
        &:hover,
        &:focus {
          color: $primary;
        }
        svg {
          display: inline-block;
          margin-right: 14px;
        }
      }
      .router-link-active {
        color: $primary;
      }

      @include media-breakpoint-up(lg) {
        padding-right: $ba-nav-main-padding-horizontal-lg;
        padding-left: $ba-nav-main-padding-horizontal-lg;
        .nav-link {
          color: $ba-navbar-link-color;
          white-space: nowrap;
          //color: #fff;
          font-weight: bold;
          height: 52px;
          padding: 9px 3px 0 3px;
          display: block;
          //border-bottom: 2px solid $gray-300;
          //transition: color .2s linear, border-bottom-color .2s linear;
          transition: color .2s linear;
          &:hover,
          &:focus {
            color: $primary;
            //border-color: transparent;
          }
          svg {
            display: none;
          }
        }
        .router-link-active {
          border-color: $primary;
        }
      }

      @include media-breakpoint-up(xl) {
        padding-right: $ba-nav-main-padding-horizontal-xl;
        padding-left: $ba-nav-main-padding-horizontal-xl;
        &::after {
          content: "";
          display: block;
          background: $gray-300;
          height: 38px;
          width: 2px;
          position: absolute;
          top: 14px;
          right: -1px;
          transition: all .2s linear;
        }
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
          &::after {
            display: none;
          }
        }
      }
    }

    .individual-width > a {
      @include media-breakpoint-up(lg) {
        //width: 16rem;
        width: auto;//13rem;
      }
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
      margin: 18px -3px 0 0;
      transition: margin-top .2s linear;
      .nav-item {
        .nav-link {
          text-align: center;
          border-bottom-width: 0;
          height: 70px;
          padding-top: 18px;
          position: relative;
          overflow: visible;
          transition: color .2s linear, padding-top .2s linear, height .2s linear;
          .text {
            position: relative;
            z-index: 10;
          }
          &::before {
            z-index: 1;
            content: "";
            display: block;
            width: 100%;
            position: absolute;
            height: calc(100% - 2px);
            top: 0;
            left: 0;
            background: #fff;
          }
          &::after {
            content: "";
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            left: calc(50% - 10px);
            background: #fff;
            border-left: 2px solid $gray-300;
            border-top: 2px solid $gray-300;
            transform: rotate(-135deg);
            bottom: 5px;
            z-index: 0;
            transition: all .2s ease-out;
          }
          &:hover, &:active,
          &.router-link-active {
            &::after {
              bottom: -10px;
            }
          }
        }
        &.external {
          .nav-link {
            &::before,
            &::after {
              display: none;
            }
          }
        }
      }
    }

    @include media-breakpoint-up(xl) {
      //min-width: 890px;
      .nav-item {
        font-size: $ba-nav-item-font-size-xl;
        .nav-link {
          white-space: nowrap;
          &:hover, &:active {
            text-decoration: none;
          }
        }
      }
    }
  }
  .fixed .main-nav {
    @include media-breakpoint-up(lg) {
      margin-top: 3px;
      .nav-item {
        .nav-link {
          height: 44px;
          padding-top: 8px;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      .nav-item {
        &::after {
          top: 9px;
          height: 26px;
        }
      }
    }
  }
</style>

<i18n lang="yaml" locale="de">
  components.partials.header.ba_navigation.studypreparation: 'Studienvorbereitung'
  components.partials.header.ba_navigation.jobmarket: 'Stellenmarkt'
  components.partials.header.ba_navigation.companysearch: 'Suche Ausbildungsbetriebe'
  components.partials.header.ba_navigation.register: 'Suche Auszubildende'
  components.partials.header.ba_navigation.profile: 'Suche Auszubildende'
  components.partials.header.ba_navigation.applicantsearch: 'Suche Auszubildende'
  components.partials.header.ba_navigation.mathx3: 'MathX³'
</i18n>
