var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.isAuthenticated
    ? _c(
        "b-navbar-nav",
        { staticClass: "topbar-menu-container" },
        [
          _c("b-nav-item", { key: "home", attrs: { to: { name: "home" } } }, [
            _c("span", { staticClass: "has-text-icon" }, [
              _c(
                "span",
                { staticClass: "icon-circle bg-info" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "link-icon",
                    attrs: { icon: "home" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.$t("action_home")))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("ba-navbar-menu", {
            attrs: { "message-count": _vm.messageCount }
          }),
          _vm._v(" "),
          _c(
            "b-nav-item",
            {
              key: "logout",
              on: {
                click: function($event) {
                  return _vm.logout()
                }
              }
            },
            [
              _c("span", { staticClass: "has-text-icon" }, [
                _c(
                  "span",
                  { staticClass: "icon-circle bg-info" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "link-icon",
                      attrs: { icon: "user" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("action_logout")))
                ])
              ])
            ]
          )
        ],
        1
      )
    : _c(
        "b-navbar-nav",
        { staticClass: "topbar-menu-container" },
        [
          _c("b-nav-item", { key: "home", attrs: { to: { name: "home" } } }, [
            _c("span", { staticClass: "has-text-icon" }, [
              _c(
                "span",
                { staticClass: "icon-circle bg-info" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "link-icon",
                    attrs: { icon: "home" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.$t("action_home")))
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "b-nav-dropdown",
            {
              ref: "login-dropdown",
              attrs: { right: "", variant: "text", "no-caret": true },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function() {
                    return [
                      _c("span", { staticClass: "has-text-icon" }, [
                        _c(
                          "span",
                          { staticClass: "icon-circle bg-info" },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "link-icon",
                              attrs: { icon: "sign-in-alt" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "text" }, [
                          _vm._v(_vm._s(_vm.$t("action_login")))
                        ])
                      ])
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _vm._v(" "),
              _c("ba-login-form", {
                attrs: { id: "nav-login-form-desktop" },
                on: { close: _vm.closeLoginDropdown }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { key: "register", attrs: { to: { name: "register" } } },
            [
              _c("span", { staticClass: "has-text-icon" }, [
                _c(
                  "span",
                  { staticClass: "icon-circle bg-info" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "link-icon",
                      attrs: { icon: "user-plus" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("action_register")))
                ])
              ])
            ]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }