'use strict';

import state from './auth.state';
import router from '@/router';
import { login, logout } from '@/api/security';

const actions = {
  login ({ commit, dispatch }, userData) {
    return login(userData)
      .then(function ({ data: response }) {
        commit('SET_TOKEN', response);
        router.push({ name: 'dashboard' });
        dispatch('updateProfile');
      });
    ;
    // .catch(dispatch('logout'))
  },
  loginByToken ({ commit, dispatch }, token) {
    commit('SET_TOKEN', token);
    dispatch('updateProfile');
  },
  logout ({ commit }, redirectRoute) {
    commit('SET_TOKEN', { token: '' });
    commit('UPDATE_USER', { type: '' });
    logout()
      .finally(function () {
        if (redirectRoute) {
          router.push(redirectRoute);
        } else if (!router.currentRoute.meta.public) {
          router.push({ name: 'home' });
        }
      })
    ;
  },
  logoutIfAuthenticated ({ dispatch, state }) {
    if (state.token.token.length > 0) {
      dispatch('logout');
    }
  },
};

const mutations = {
  SET_TOKEN (state, token = {}) {
    state.token.token = token.token ? token.token : '';
    state.token.security = token.security ? token.security : '';
  },
};

const getters = {
  isAuthenticated: function (state) {
    return state.token.token.length > 0;
  },
  isGranted: function (state, getters) {
    const permissions = state.token.security ? JSON.parse(atob(state.token.security)) : [];

    return (permission) => {
      if (!getters.isAuthenticated) {
        return false;
      }

      return permissions.includes(permission);
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
