var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar-nav",
    { staticClass: "main-nav" },
    [
      _c(
        "b-nav-item",
        { attrs: { to: { name: "companysearch" } } },
        [
          _c("font-awesome-icon", { attrs: { icon: "building" } }),
          _vm._v(" "),
          _c("span", { staticClass: "text" }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t(
                    "components.partials.header.ba_navigation.companysearch"
                  )
                )
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isAuthenticated
        ? _c(
            "b-nav-item",
            {
              staticClass: "individual-width",
              attrs: { to: { name: "register" } }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: "user" } }),
              _vm._v(" "),
              _c("span", { staticClass: "text" }, [
                _vm._v(
                  "\n    " +
                    _vm._s(
                      _vm.$t(
                        "components.partials.header.ba_navigation.register"
                      )
                    )
                )
              ])
            ],
            1
          )
        : _vm.isApplicantAccount
        ? _c(
            "b-nav-item",
            {
              staticClass: "individual-width",
              attrs: { to: { name: "profile" } }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: "user" } }),
              _vm._v(" "),
              _c("span", { staticClass: "text" }, [
                _vm._v(
                  "\n    " +
                    _vm._s(
                      _vm.$t("components.partials.header.ba_navigation.profile")
                    )
                )
              ])
            ],
            1
          )
        : _vm.isCompanyAccount
        ? _c(
            "b-nav-item",
            {
              staticClass: "individual-width",
              attrs: { to: { name: "applicantsearch" } }
            },
            [
              _c("font-awesome-icon", { attrs: { icon: "user" } }),
              _vm._v(" "),
              _c("span", { staticClass: "text" }, [
                _vm._v(
                  "\n    " +
                    _vm._s(
                      _vm.$t(
                        "components.partials.header.ba_navigation.applicantsearch"
                      )
                    )
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-nav-item",
        {
          staticClass: "external",
          attrs: {
            href: "https://www.weincampus-neustadt.de/weincampus/stellenmarkt",
            target: "_blank"
          }
        },
        [
          _c("font-awesome-icon", { attrs: { icon: "book" } }),
          _vm._v(" "),
          _c("span", { staticClass: "text" }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t("components.partials.header.ba_navigation.jobmarket")
                )
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-nav-item",
        {
          staticClass: "external",
          attrs: {
            href: "https://www.weincampus-neustadt.de/kontakt",
            target: "_blank"
          }
        },
        [
          _c("font-awesome-icon", { attrs: { icon: "link" } }),
          _vm._v(" "),
          _c("span", { staticClass: "text" }, [_vm._v("\n    Kontakt")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }