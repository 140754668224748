import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import i18n from '@/config/translations';
import store from '@/store';

const apiRequestCache = {};

function handleJsonResponse (responsePromise) {
  const resolveResponse = function (response, resolve, reject) {
    if (response && !response.isAxiosError) {
      const responseData = response.data;
      const meta = responseData.meta;
      const type = responseData['@type'];
      if (meta) {
        const status = meta.status;
        const dataField = meta.dataField;
        const data = responseData[dataField];
        if (status === 'SUCCESS') {
          resolve({ data, response });
        } else {
          reject({ data, status, response });
        }
      } else if (type) {
        switch (type) {
          case 'hydra:Collection':
            resolve({ data: responseData['hydra:member'], response });
            break;
          case 'hydra:Error':
            // eslint-disable-next-line no-case-declarations
            const data = {
              title: responseData.title,
              description: responseData.description,
            };
            // eslint-disable-next-line no-case-declarations
            const status = 'Error';
            reject({ data, status, response });
            break;
          default:
            resolve({ data: responseData, response });
            break;
        }
      }
      // handle error response
    } else if (response && response.response) {
      const rejectResponse = response.response;
      reject({ data: rejectResponse.data, status: rejectResponse.status, response: rejectResponse });
    }
  };

  return new Promise(function (resolve, reject) {
    responsePromise
        .then((response) => resolveResponse(response, resolve, reject))
        .catch(undefined)
    ;
  });
}

/**
 * Axios instance with sane defaults
 * @memberOf api
 * @property {function} get  HTTP get
 * @property {function} delete  HTTP delete
 * @property {function} post  HTTP post
 * @property {function} put  HTTP put
 * @property {function} patch  HTTP patch
 */

class Http {
  responseInterceptor;
  debugTokenInterceptor;
  debugTokenLink;
  mockAdapter;
  apiUrl;
  defaultLanguageSet = false;
  constructor () {
    const apiUrl = process.env.API_URL ? process.env.API_URL : '';
    this.apiUrl = apiUrl;
    axios.defaults.baseURL = apiUrl;
    if (process.env.API_XDEBUG !== undefined) {
      axios.defaults.withCredentials = process.env.API_XDEBUG;
    }
    this.setAxiosDefaults();
    if (apiUrl === '') {
      this.enableMockAdapter();
    }
    if (process.env.NODE_ENV === 'development') {
      this.enableDebugInterceptor();
    }
  }

  setAxiosDefaults () {
    if (!this.defaultLanguageSet && i18n && i18n.locale) {
      axios.defaults.headers.common['Accept-Language'] = i18n.locale;
      // axios.defaults.headers.common['X-User-Language'] = i18n.locale;
      this.defaultLanguageSet = true;
    }
    if (store.state && store.state.auth && store.state.auth.token.token) {
      axios.defaults.headers.common.Authorization = `Bearer ${store.state.auth.token.token}`;
    }
  }

  enableDebugInterceptor () {
    const interceptor = (response) => {
      this.debugTokenLink = typeof response.headers !== 'undefined' ? response.headers['x-debug-token-link'] : undefined;
      if (this.debugTokenLink !== undefined && this.debugTokenInterceptor !== undefined) {
        this.debugTokenInterceptor(this.debugTokenLink);
      }
      return response;
    };
    this.addResponseSuccessInterceptor(interceptor, interceptor);
  }

  enableMockAdapter () {
    this.mockAdapter = new MockAdapter(axios);
    this.enableRequestLogging();
  }

  enableRequestLogging () {
    axios.interceptors.request.use(
        function (request) {
          const info = request.method.toUpperCase() + ' ' + request.url;
          console.info(`> ${info}`, request.data);
          return request;
        },
    );
    axios.interceptors.response.use(
        function (response) {
          const info = response.config.method.toUpperCase() + ' ' + response.config.url;
          console.info(`< ${info}`, response.status, response.data);
          return response;
        },
        function (error) {
          const info = error.config.method.toUpperCase() + ' ' + error.config.url;
          console.info(`< ${info}`, error.response.status, error.response.data);
          return error;
        },
    );
  }

  isMockingEnabled () {
    return this.mockAdapter !== null && this.mockAdapter !== undefined;
  }

  addResponseSuccessInterceptor (responseInterceptor) {
    this.responseInterceptor = responseInterceptor;
    axios.interceptors.response.use(this.responseInterceptor, undefined);
  }

  addResponseFailedInterceptor (responseInterceptor) {
    this.responseInterceptor = responseInterceptor;
    axios.interceptors.response.use(undefined, this.responseInterceptor);
  }

  addDebugTokenInterceptor (debugTokenInterceptor) {
    this.debugTokenInterceptor = debugTokenInterceptor;
    if (this.debugTokenInterceptor !== undefined && this.debugTokenLink !== undefined) {
      debugTokenInterceptor(this.debugTokenLink);
    }
  }

  get (path, params) {
    this.setAxiosDefaults();
    const config = {
      method: 'GET',
      url: path,
      responseType: 'json',
    };
    if (params) {
      config.params = params;
    }
    return handleJsonResponse(axios.request(config));
  }

  getCached (path, maxLifetime = undefined) {
    if (maxLifetime === undefined) {
      maxLifetime = 600;
    }
    let apiRequestCache = localStorage.getItem('campusMatchCache');
    apiRequestCache = apiRequestCache ? JSON.parse(apiRequestCache) : {};

    if (Object.prototype.hasOwnProperty.call(apiRequestCache, path)) {
      const { response, requestedAt } = apiRequestCache[path];

      if ((maxLifetime === null) || (requestedAt + (maxLifetime * 1000) >= Date.now())) {
        return new Promise((resolve) => {
          resolve(response);
        });
      }
    }

    return this.get(path).then((response) => {
      apiRequestCache[path] = {
        requestedAt: Date.now(),
        response: response,
      };
      localStorage.setItem('campusMatchCache', JSON.stringify(apiRequestCache));
      return response;
    });
  }

  delete (path) {
    this.setAxiosDefaults();
    return handleJsonResponse(axios.request({
      method: 'DELETE',
      url: path,
      responseType: 'json',
    }));
  }

  post (path, payload) {
    this.setAxiosDefaults();
    if (payload && typeof payload === 'object' && i18n && i18n.locale) {
      payload.locale = i18n && i18n.locale;
    }
    return handleJsonResponse(axios.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload,
    }));
  }

  put (path, payload) {
    this.setAxiosDefaults();
    if (payload && typeof payload === 'object' && i18n && i18n.locale) {
      payload.locale = i18n && i18n.locale;
    }
    return handleJsonResponse(axios.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: payload,
    }));
  }

  postMultipart (path, payload) {
    this.setAxiosDefaults();
    return handleJsonResponse(axios.request({
      method: 'POST',
      url: path,
      data: payload,
    }));
  }

  patch (path, payload) {
    this.setAxiosDefaults();
    return handleJsonResponse(axios.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload,
    }));
  }

  getBinary (path) {
    this.setAxiosDefaults();
    return axios.get(path);
  }

  download (path, fileName, onDownloadProgress) {
    this.setAxiosDefaults();
    return new Promise(function (resolve, reject) {
      axios.get(path, {
        responseType: 'blob',
        onDownloadProgress: onDownloadProgress,
      }).then(function (response) {
        if (response.data) {
          let type = 'application/json';
          if (response.headers && response.headers['content-type']) {
            type = response.headers['content-type'];
          }
          const blob = new Blob([response.data], { type: type });
          const fileUrl = window.URL.createObjectURL(blob);
          const openInlineMimeTypes = ['application/pdf'];
          if (openInlineMimeTypes.includes(type)) {
            window.open(fileUrl, '_blank');
            resolve(response);
          } else {
            const linkElement = document.createElement('a');
            linkElement.href = fileUrl;
            linkElement.setAttribute('download', fileName);
            document.body.appendChild(linkElement);
            linkElement.click();
            resolve(response);
            linkElement.remove();
          }
        } else {
          reject(response);
        }
      });
    });
  }
}

export default new Http();
